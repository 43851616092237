import { Controller } from "@hotwired/stimulus";
import Pristine from "pristinejs";
import SmoothScroll from "smooth-scroll";

// Connects to data-controller="form"
export default class extends Controller {
  connect() {
    this.scroll = new SmoothScroll();
  }

  disconnect() {
    if (this.pristine) {
      this.pristine.destroy();
    }
    this.scroll.destroy();
  }

  submit(event) {
    event.preventDefault();

    if (!this.pristine) {
      this.setPristine();
    }
    const valid = this.pristine.validate();
    if (valid) {
      event.target.submit();
    } else {
      const firstErrorElement = document.querySelector(".has-error");
      this.scroll.animateScroll(firstErrorElement, null, {
        updateURL: false
      });
    }
  }

  setPristine() {
    if (this.pristine) {
      this.pristine.destroy();
    }
    this.pristine = new Pristine(this.element, {
      errorTextClass: "text-red-500 text-sm"
    });
  }
}
