import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="navbar"
export default class extends Controller {
  static targets = ["nav", "backdrop"];

  show() {
    const nav = this.navTarget;
    const body = document.querySelector("body");

    nav.classList.add("show");
    body.style.overflow = "hidden";
  }

  hide() {
    const nav = this.navTarget;
    const body = document.querySelector("body");

    nav.classList.remove("show");
    body.removeAttribute("style");
  }
}
