import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="salary-range"
export default class extends Controller {
  static targets = ["trigger", "input"];

  connect() {
    this.updateState();
  }

  updateState() {
    this.inputTargets.forEach((element) => {
      element.disabled = this.triggerTarget.checked;
      if (this.triggerTarget.checked) {
        element.value = "";
      }
    });
  }
}
