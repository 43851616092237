import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="nested-form"
export default class extends Controller {
  static targets = ["list", "item", "template"];
  static values = { counter: { type: Number, default: 1 } };

  connect() {
    this.counter = this.itemTargets.length;
    if (this.counter === 0) {
      this.addAssociation();
    }
  }

  addAssociation() {
    const content = this.templateTarget.innerHTML
      .replace(/TEMPLATE_INDEX/, this.counter + 1)
      .replace(/TEMPLATE_RECORD/g, new Date().valueOf());
    this.listTarget.insertAdjacentHTML("beforeend", content);
    this.counter++;
  }
}
