import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["modal"];

  show() {
    const modal = this.modalTarget;
    const backdrop = document.createElement("div");
    const body = document.querySelector("body");
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

    backdrop.className = "modal-backdrop fade";
    body.append(backdrop);
    modal.style.display = "block";

    setTimeout(() => {
      modal.classList.add("show");
      backdrop.classList.add("show");
      body.style.paddingRight = `${scrollBarWidth}px`;
      body.style.overflow = "hidden";
    }, 1);
  }

  hide() {
    const modal = this.modalTarget;
    const backdrop = document.querySelector(".modal-backdrop");
    const body = document.querySelector("body");

    modal.classList.remove("show");
    backdrop.classList.remove("show");

    setTimeout(() => {
      modal.removeAttribute("style");
      backdrop.remove();
      body.removeAttribute("style");
    }, 300);
  }

  click(event) {
    if (event.target.classList.contains("modal")) {
      this.hide();
    }
  }
}
