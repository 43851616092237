import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="dependent-input"
export default class extends Controller {
  static targets = ["trigger", "input"];

  connect() {
    this.updateState();
  }

  updateState() {
    if (this.isSelected()) {
      this.inputTarget.disabled = false;
    } else {
      this.inputTarget.disabled = true;
      this.inputTarget.value = "";
    }
  }

  isSelected() {
    if (this.triggerTarget.type === "checkbox") {
      return this.triggerTarget.checked;
    }

    return this.triggerTarget.value !== "";
  }
}
