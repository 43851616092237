import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="address"
export default class extends Controller {
  static targets = ["prefecture", "city", "town"];

  update(event) {
    const zipCode = event.target.value;
    if (zipCode.length === 7 && /^[0-9]*$/.test(zipCode)) {
      const endpoint = `/api/address?zip_code=${zipCode}`;
      fetch(endpoint)
        .then((response) => response.ok && response.json())
        .then((address) => {
          if (address) {
            this.prefectureTarget.value = address.prefecture;
            this.cityTarget.value = address.city;
            this.townTarget.value = address.town;
          }
        });
    }
  }
}
