import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="character-counter"
export default class extends Controller {
  static targets = ["input", "counter"];

  connect() {
    this.update();
  }

  update() {
    this.counterTarget.innerHTML = this.inputTarget.value.length;
  }
}
