import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

// Connects to data-controller="date-range-picker"
export default class extends Controller {
  static targets = ["fromDate", "toDate"];

  connect() {
    this.fromDate = flatpickr(this.fromDateTarget, {
      dateFormat: "Y/m/d",
      onChange: (selectedDates) => {
        this.toDate.config.minDate = selectedDates[0];
      }
    });

    this.toDate = flatpickr(this.toDateTarget, {
      dateFormat: "Y/m/d",
      onChange: (selectedDates) => {
        this.fromDate.config.maxDate = selectedDates[0];
      }
    });
  }
}
