import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fee-simulator"
export default class extends Controller {
  static targets = ["amount", "note"];

  calculate(element) {
    const selectElement = element.target;
    const selectedValue = Number(selectElement.value);
    this.amountTarget.innerHTML = selectedValue.toLocaleString();
    this.updateNote(selectElement.options[selectElement.selectedIndex].text);
  }

  updateNote(selected) {
    let note = "";
    switch (selected) {
      case "現地食材キュレーション":
        note = "※別途店舗を臨時休業する日数分の想定売上が計上される場合がございます。";
        break;
      case "商品開発":
        note = "※別途売上に応じたパーセンテージが生じる場合がございます。";
    }
    this.noteTarget.innerHTML = note;
  }
}
