import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

// Connects to data-controller="datetime-picker"
export default class extends Controller {
  static targets = ["input"];

  connect() {
    flatpickr(this.inputTarget, {
      dateFormat: "Y/m/d H:i",
      enableTime: true,
      time_24hr: true
    });
  }
}
