import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="new-job"
export default class extends Controller {
  static targets = ["numOfChefsWrapper", "numOfChefs", "submitBtn"];

  connect() {}

  changeJobCategory(event) {
    const selectedOption = event.target.querySelector(`option[value="${event.target.value}"]`);

    if (selectedOption.dataset.requiredNumOfChefs === "true") {
      this.numOfChefsWrapperTarget.classList.remove("hidden");
      this.numOfChefsTarget.disabled = false;
    } else {
      this.numOfChefsWrapperTarget.classList.add("hidden");
      this.numOfChefsTarget.disabled = true;
    }

    this.submitBtnTarget.value =
      selectedOption.dataset.requiredNumOfChefs === "true" || event.target.value === ""
        ? "料理人検索に進む"
        : "依頼フローに進む";
  }
}
