import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="flash"
export default class extends Controller {
  static targets = ["notice", "alert", "wrapper"];

  connect() {
    const notice = this.noticeTarget;
    const alert = this.alertTarget;
    const wrapper = this.wrapperTarget;

    if (!notice.classList.contains("hidden")) {
      notice.classList.add("show");
    }
    if (!alert.classList.contains("hidden")) {
      alert.classList.add("show");
    }

    setTimeout(() => {
      notice.classList.remove("show");
      alert.classList.remove("show");
    }, 5000);

    setTimeout(() => {
      wrapper.classList.add("hidden");
    }, 5301);
  }
}
